import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SimpleMap from "../components/map/map"
import { createUseStyles } from "react-jss"
import { graphql } from "gatsby"
import Quote from "src/components/Quote"

const useStyles = createUseStyles({
  p: {
    width: `60%`,
    margin: `auto`,
    textAlign: `center`,
    fontSize: 20,
    lineHeight: 1.6,
    marginBottom: `2vw`,
  },
  "@media (max-width: 850px)": {
    p: {
      width: `100%`,
    },
  },
})

interface Props {
  data: GatsbyTypes.DiscoverQuery
}

const IndexPage = ({ data }: Props) => {
  const classes = useStyles()
  const [height, setHeight] = useState<number>(0)

  return (
    <Layout setHeaderHeight={setHeight}>
      <SEO title="Discover" />
      <main
        style={{
          // height: `100vh`,
          marginTop: `${height}px`,
          height: `100%`,
          // minHeight: `calc(100vh - ${height}px)`,
          padding: `6.6vmax 4vw 4vw`,
          boxSizing: "border-box",
        }}
      >
        <h1 style={{ marginBottom: `2vw`, textAlign: `center` }}>Discover</h1>
        {data.contentfulQuote != null && <Quote data={data.contentfulQuote} />}
        {/* <p
          className={classes.p}
          style={{ fontSize: 20, lineHeight: 1.6, marginTop: `2vw` }}
        >
          Leatherwood Mountains offers hiking, fishing, tubing, horseback
          riding, swimming, tennis, and on weekends, dining at the Saddlebook
          Grill.
        </p> */}
        <p className={classes.p}>
          Whether you prefer to stay in Leatherwood or explore nearby towns,
          you'll find plenty of adventures awaiting you near Second Breakfast.
        </p>
        <h3
          style={{
            marginBottom: `2vw`,
            textAlign: `center`,
            fontSize: 24,
          }}
        >
          <a href="https://www.leatherwoodmountains.com/" target="_blank">
            Within Leatherwood
          </a>
        </h3>

        <p className={classes.p}>
          Leatherwood Mountains offers hiking, fishing, tubing, horseback
          riding, swimming, tennis, and on weekends, dining at the Saddlebook
          Grill.
        </p>
        <h3
          style={{
            marginBottom: `2vw`,
            textAlign: `center`,
            fontSize: 24,
          }}
        >
          <a href="https://www.visitnc.com/high-country" target="_href">
            Around the Area
          </a>
        </h3>
        <p className={classes.p}>
          Click on the map below to view the attractions throughout North
          Carolina's High Country:
        </p>

        <SimpleMap
          center={{
            lat: 36.120265,
            lng: -81.473218,
          }}
          zoom={10}
        />
      </main>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query Discover {
    contentfulQuote(pageId: { eq: "discover" }) {
      ...Quote
    }
  }
`
