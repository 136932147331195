import React, { useState } from "react"
import GoogleMapReact from "google-map-react"
import { Marker } from "./marker"
import { places } from "./places"

interface Props {
  center: {
    lat: number
    lng: number
  }
  zoom: number
}

const SimpleMap = (props: Props) => {
  const [showIdx, setShowIdx] = useState<number | null>(null)

  return (
    // Important! Always set the container height explicitly
    <div
      style={{ height: "70vh", width: "100%" }}
      onClick={() => {
        setShowIdx(null)
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
        defaultCenter={props.center}
        defaultZoom={props.zoom}
      >
        {places.map((place, i) => (
          <Marker
            key={i}
            lat={place.lat}
            lng={place.lng}
            place={place.place}
            show={i === showIdx}
            onClick={() => {
              setShowIdx(i === showIdx ? null : i)
            }}
          />
        ))}
      </GoogleMapReact>
    </div>
  )
}

export default SimpleMap
