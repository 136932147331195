import { Place } from "./marker"

export const places: { place: Place; lat: number; lng: number }[] = [
  {
    place: {
      name: "Leatherwood Mountains",
      home: true,
      url: "https://www.leatherwoodmountains.com/",
      attractions: [],
    },
    lat: 36.120308,
    lng: -81.473686,
  },
  {
    place: {
      name: "Wilkesboro / North Wilkesboro",
      url: "https://www.wilkescountytourism.com/",
      minutes: 30,
      attractions: [
        {
          name: `W. Kerr Scott Reservoir`,
          things: [
            "hiking",
            "swimming",
            "fishing",
            "boating",
            "ranger-led trail tours and children’s activities",
            "disc golf",
            "environmental center",
          ],
        },
      ],
    },
    lat: 36.145273,
    lng: -81.161184,
  },
  {
    place: {
      name: "Lenoir",
      url: "https://explorecaldwell.com/",
      minutes: 30,
    },
    lat: 35.91402,
    lng: -81.538985,
  },
  {
    place: {
      name: "Boone",
      url: "https://www.exploreboone.com/",
      minutes: 45,
    },
    lat: 36.216795,
    lng: -81.674552,
  },
  {
    place: {
      name: "Blowing Rock",
      url: "https://blowingrock.com/",
      minutes: 50,
    },
    lat: 36.135129,
    lng: -81.677606,
  },
  {
    place: {
      name: "Valle Crucis",
      url: "https://www.exploreboone.com/about/local-communities/valle-crucis/",
      minutes: 60,
    },
    lat: 36.209294,
    lng: -81.778444,
  },
  {
    place: {
      name: "Seven Devils",
      url: "https://www.sevendevils.net/",
      minutes: 75,
    },
    lat: 36.14432,
    lng: -81.826041,
  },
  {
    place: {
      name: "Banner Elk",
      url: "https://www.bannerelk.com/",
      minutes: 80,
    },
    lat: 36.163182,
    lng: -81.871502,
  },
  {
    place: {
      name: "Beech Mountain",
      url: "https://beechmtn.com/",
      minutes: 90,
    },
    lat: 36.210958,
    lng: -81.889003,
  },
]
