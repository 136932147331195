import React from "react"
import { FaStar } from "react-icons/fa"

export interface Attraction {
  name: string
  things: string[]
}
export interface Place {
  name: string
  home?: boolean
  url: string
  minutes?: number
  attractions: Attraction[]
}

interface InfoWindowProps {
  place: Place
}

const minutesToString = (minutes: number): string => {
  const hours = Math.floor(minutes / 60)
  const min = minutes % 60

  return `${hours > 0 ? `${hours} hour and ` : ""}${min} minutes`
}

export const InfoWindow = ({ place }: InfoWindowProps) => {
  return (
    <div
      style={{
        position: "relative",
        width: 220,
        left: -110,
        transform: `translateY(calc(-100% - 15px))`,
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
        padding: 10,
        zIndex: 3,
        fontFamily: `"EB Garamond", serif`,
      }}
    >
      <div style={{ fontSize: 16 }}>
        <p>{place.name}</p>
        {place.minutes && (
          <p style={{ fontStyle: `italic` }}>
            ({minutesToString(place.minutes)})
          </p>
        )}
        <a href={place.url} target="_blank">
          Visit site
        </a>
      </div>
    </div>
  )
}

interface Props {
  show: boolean
  place: Place
  onClick: () => void
}

// Marker component
export const Marker = ({ show, place, onClick }: Props) => {
  const markerStyle = {
    border: "1px solid white",
    borderRadius: "50%",
    height: place.home ? 18 : 10,
    width: place.home ? 18 : 10,
    backgroundColor: place.home ? "#FF69B4" : show ? "red" : "blue",
    cursor: "pointer",
    zIndex: 10,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  }

  return (
    <>
      <div
        style={markerStyle}
        onClick={e => {
          e.stopPropagation()
          onClick()
        }}
      >
        {place.home && <FaStar color="white" size={12} />}
      </div>
      {show && <InfoWindow place={place} />}
    </>
  )
}
